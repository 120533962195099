import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

// Connects to data-controller="tom-select"
// To alter the sort order of the search results, you can use the sortField setting.  In this is example the original
// order will take precedence over the search score.
// 'tom-select-settings-value': "{\"sortField\":[{\"field\":\"$order\"},{\"field\":\"$score\"}]}"
export default class extends Controller {
  static values = {
    options: Array,
    settings: Object
  }

  connect () {
    this.settings = this.settingsValue
    this.initTomSelect()

    document.addEventListener('turbo:morph', () => this.reinitializeTomSelect())
  }

  disconnect () {
    this.destroyTomSelect()
    document.removeEventListener('turbo:morph', () => this.reinitializeTomSelect())
  }

  initTomSelect () {
    if (this.element) {
      if (this.hasOptionsValue) {
        this.settings = { ...this.settings, ...this.customRenderer() }
      }
      this.select = new TomSelect(this.element, this.settings)
    }
    if (this.element.classList.contains('rounded-l-none')) {
      this.select.control.style.borderTopLeftRadius = '0'
      this.select.control.style.borderBottomLeftRadius = '0'
    }
    if (this.element.classList.contains('rounded-r-none')) {
      this.select.control.style.borderTopRightRadius = '0'
      this.select.control.style.borderBottomRightRadius = '0'
    }
    if (this.element.classList.contains('py-0')) {
      this.select.control.style.paddingTop = '3px'
      this.select.control.style.paddingBottom = '3px'
    }
  }

  reinitializeTomSelect () {
    this.destroyTomSelect()
    this.initTomSelect()
  }

  destroyTomSelect () {
    if (this.select) {
      this.select.destroy()
    }
  }

  customRenderer () {
    return {
      render: {
        option: (data, escape) => {
          return '<div>' +
            '<p class="text-gray-900 cursor-default select-none text-sm">' + escape(data.title) + '</p>' +
            '<span class="text-gray-500 text-xs">' + escape(data.subtitle) + '</span>' + '</div>'
        },
        item: (data, escape) => {
          return '<div title="' + escape(data.subtitle) + '">' + escape(data.title) + '</div>'
        }
      },
      valueField: 'id',
      searchField: 'title',
      options: this.optionsValue
    }
  }
}
