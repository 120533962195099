import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 2000
    }
  }

  connect () {
    this.timeout = setTimeout(() => this.element.remove(), this.delayValue)
  }

  disconnect () {
    clearTimeout(this.timeout)
  }
}
