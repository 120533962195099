import Viewer from 'bpmn-js/lib/Viewer'
import Modeler from 'bpmn-js/lib/Modeler'
import NavigatedViewer from 'bpmn-js/lib/NavigatedViewer'

import QlarityViewerModule from '../bpmn/qlarity_viewer_module'
import QlarityModelerModule from '../bpmn/qlarity_modeler_module'
import QlarityExtension from '../bpmn/qlarity_extension'

import LintModule from 'bpmn-js-bpmnlint'
import LintConfig from '../bpmn/bpmnlint_config'
import baseConfig from '../bpmn/bpmnlint.baseConfig'

import 'bpmn-js-bpmnlint/dist/assets/css/bpmn-js-bpmnlint.css'

export class Editor {
  static for (target) {
    const editor = target.bpmnEditor || window.bpmnEditors[target.id] || window.bpmnEditor

    if (!editor) {
      console.info('No BPMN Editor found target element or window')
      return
    }

    return editor
  }

  constructor ({ mode, container, lintEnabled, lintRules }) {
    this.mode = mode
    this.container = container
    this.lintEnabled = lintEnabled || false
    this.lintRules = lintRules || {}
  }

  initialize () {
    if (this.instance) return

    this.instance = new this.EditorClass({
      container: this.container,
      additionalModules: this.additionalModules,
      moddleExtensions: this.moddleExtensions,
      linting: {
        active: this.lintEnabled,
        bpmnlint: this.lintConfig
      }
    })

    this.container.querySelector('.bjsl-button')?.setAttribute('type', 'button')

    this.expose()
  }

  expose () {
    this.container.bpmnEditor = this
    window.bpmnEditors ??= {}
    window.bpmnEditors[this.container.id] = this
    window.bpmnEditor = this
  }

  reinitialize () {
    this.destroy()
    this.initialize()
  }

  destroy () {
    if (this.instance) {
      this.instance.destroy()
    }
    this.instance = null
    this.container.bpmnEditor = null
  }

  async loadEmptyDiagram () {
    const diagram = `
      <?xml version="1.0" encoding="UTF-8"?>
      <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
        <bpmn2:process id="Process_1" isExecutable="false"></bpmn2:process>
        <bpmndi:BPMNDiagram id="BPMNDiagram_1">
          <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1"></bpmndi:BPMNPlane>
        </bpmndi:BPMNDiagram>
      </bpmn2:definitions>
    `

    return await this.loadFromXML(diagram)
  }

  async loadFromXML (bpmnXML) {
    if (!this.instance) return false

    try {
      await this.instance.importXML(bpmnXML)

      this.autoFit()

      return true
    } catch (err) {
      console.error('could not import BPMN 2.0 diagram', err)

      return false
    }
  }

  async loadFromFile (file) {
    const content = await file.text()

    this.loadFromXML(content)
  }

  async loadFromURL (url) {
    const response = await fetch(url)
    const xml = await response.text()

    if (xml === '') {
      return await this.loadEmptyDiagram()
    } else {
      return await this.loadFromXML(xml)
    }
  }

  async diagramXML () {
    const { xml } = await this.instance.saveXML({ format: true })

    return xml
  }

  autoFit () {
    this.canvas.zoom(this.scale, { x: this.viewbox.inner.x, y: 0 })
    this.canvas.zoom('fit-viewport', 'auto')
  }

  lint () {
    this.instance.get('linting').update()
  }

  redrawAll () {
    Object.keys(this.elementRegistry._elements).forEach(id => this.redrawElement(id))
  }

  redrawActivties () {
    this.activties.forEach(activity => this.redrawElement(activity.id))
  }

  getElementById (id) {
    return this.elementRegistry.get(id)
  }

  redrawElement (id) {
    const element = this.elementRegistry.get(id)
    const gfx = this.elementRegistry.getGraphics(element)
    this.graphicsFactory.update('shape', element, gfx)
  }

  redrawPalette () {
    this.instance.get('palette')._rebuild()
  }

  get lintConfig () {
    return LintConfig.getLintConfig(baseConfig, this.lintRules)
  }

  get activties () {
    return this.elementRegistry.getAll().filter(element => element.type === 'bpmn:Task')
  }

  get elementRegistry () {
    return this.instance.get('elementRegistry')
  }

  get graphicsFactory () {
    return this.instance.get('graphicsFactory')
  }

  get canvas () {
    return this.instance.get('canvas')
  }

  get viewbox () {
    return this.canvas.viewbox() // viewbox.outer = Canvas Size, viewbox.inner = Diagram Size
  }

  get scale () {
    return Math.min(1, (this.viewbox.outer.width - this.viewbox.inner.x * 2) /
      this.viewbox.inner.width) // Calculate the Scale based on the page width.
  }

  get moddleExtensions () {
    return {
      ...QlarityExtension
    }
  }

  get additionalModules () {
    const modules = []

    if (this.isEditor) modules.push(QlarityModelerModule)
    if (this.isAnyViewer) modules.push(QlarityViewerModule)
    if (this.isEditor || this.isViewer) modules.push(LintModule)

    return modules
  }

  get EditorClass () {
    return this.editorMappings[this.mode]
  }

  get editorMappings () {
    return {
      viewer: Viewer,
      modeler: Modeler,
      'navigated-viewer': NavigatedViewer
    }
  }

  get isViewer () {
    return this.mode === 'viewer'
  }

  get isNavigatedViewer () {
    return this.mode === 'navigated-viewer'
  }

  get isAnyViewer () {
    return this.isViewer || this.isNavigatedViewer
  }

  get isEditor () {
    return this.mode === 'modeler'
  }
}
