import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="application--switch"
export default class extends Controller {
  static targets = ['button', 'field', 'slider']
  static values = { state: Boolean, unary: Boolean }

  toggle () {
    if (this.stateValue) {
      this.buttonTarget.classList.replace('bg-indigo-600', 'bg-gray-200')
      this.sliderTarget.classList.replace('translate-x-5', 'translate-x-0')
    } else {
      this.buttonTarget.classList.replace('bg-gray-200', 'bg-indigo-600')
      this.sliderTarget.classList.replace('translate-x-0', 'translate-x-5')
    }
    this.stateValue = !this.stateValue
    if (this.hasFieldTarget) {
      this.fieldTarget.value = this.unaryValue ? +this.stateValue : this.stateValue
    }
  }
}
