import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="application--dropdown"
// This controller works with specially annotated HTML like:
//
// <div class="relative"
//      data-controller="application--dropdown"
//      data-application--dropdown-active-target="#dropdown-button"
//      data-application--dropdown-active-class="bg-teal-600"
//      data-application--dropdown-invisible-class="opacity-0 scale-95"
//      data-application--dropdown-visible-class="opacity-100 scale-100"
//      data-application--dropdown-entering-class="ease-out duration-100"
//      data-application--dropdown-enter-timeout="100"
//      data-application--dropdown-leaving-class="ease-in duration-75"
//      data-application--dropdown-leave-timeout="75"
//      data-application--open-value="false">
//  <div role="button" tabindex="0" class="inline-block select-none"
//       data-action="click->application--dropdown#toggle click@window->application--dropdown#hide"
//       data-application--dropdown-target="button">
//    Open Dropdown
//  </div>
//  <div class="absolute pin-r mt-2 transform transition hidden opacity-0 scale-95"
//       data-application--dropdown-target="menu">
//    <div class="bg-white shadow rounded border overflow-hidden">
//      Content
//    </div>
//  </div>
// </div>
export default class extends Controller {
  static targets = ['menu', 'button']
  static values = { open: Boolean }

  get activeTarget () {
    return this.data.has('activeTarget')
      ? document.querySelector(this.data.get('activeTarget'))
      : this.element
  }

  get _activeClassList () {
    return !this.activeClass
      ? [[], []]
      : this.activeClass.split(',').map(classList => classList.split(' '))
  }

  get _visibleClassList () {
    return !this.visibleClass
      ? [[], []]
      : this.visibleClass.split(',').map(classList => classList.split(' '))
  }

  get _invisibleClassList () {
    return !this.invisibleClass
      ? [[], []]
      : this.invisibleClass.split(',').map(classList => classList.split(' '))
  }

  get _enteringClassList () {
    return !this.enteringClass
      ? [[], []]
      : this.enteringClass.split(',').map(classList => classList.split(' '))
  }

  get _leavingClassList () {
    return !this.leavingClass
      ? [[], []]
      : this.leavingClass.split(',').map(classList => classList.split(' '))
  }

  get enterTimeout () {
    const timeout = this.data.get('enterTimeout') || '0,0'
    return timeout.split(',').map(t => parseInt(t))
  }

  get leaveTimeout () {
    const timeout = this.data.get('leaveTimeout') || '0,0'
    return timeout.split(',').map(t => parseInt(t))
  }

  connect () {
    this.toggleClass = this.data.get('class') || 'hidden'
    this.visibleClass = this.data.get('visibleClass') || null
    this.invisibleClass = this.data.get('invisibleClass') || null
    this.activeClass = this.data.get('activeClass') || null
    this.enteringClass = this.data.get('enteringClass') || null
    this.leavingClass = this.data.get('leavingClass') || null

    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener('keydown', this._onMenuButtonKeydown)
    }

    this.element.setAttribute('aria-haspopup', 'true')
  }

  disconnect () {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener('keydown', this._onMenuButtonKeydown)
    }
    this.openValue = false
  }

  toggle () {
    this.openValue = !this.openValue
  }

  openValueChanged () {
    if (this.openValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  _show (cb) {
    setTimeout(
      () => {
        this.menuTarget.classList.remove(this.toggleClass)
        this.element.setAttribute('aria-expanded', 'true')
        this._enteringClassList[0].forEach(
          klass => {
            this.menuTarget.classList.add(klass)
          }
        )

        this._activeClassList[0].forEach(klass => {
          this.activeTarget.classList.add(klass)
        })
        this._invisibleClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
        this._visibleClassList[0].forEach(klass => {
          this.menuTarget.classList.add(klass)
        })
        setTimeout(
          () => {
            this._enteringClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
          },
          this.enterTimeout[0]
        )

        if (typeof cb === 'function') cb()
      }
    )
  }

  _hide (cb) {
    setTimeout(
      () => {
        this.element.setAttribute('aria-expanded', 'false')
        this._invisibleClassList[0].forEach(klass => this.menuTarget.classList.add(klass))
        this._visibleClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
        this._activeClassList[0].forEach(klass => this.activeTarget.classList.remove(klass))
        this._leavingClassList[0].forEach(klass => this.menuTarget.classList.add(klass))
        setTimeout(
          () => {
            this._leavingClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
            if (typeof cb === 'function') cb()

            this.menuTarget.classList.add(this.toggleClass)
          },
          this.leaveTimeout[0]
        )
      }
    )
  }

  _onMenuButtonKeydown = event => {
    switch (event.keyCode) {
      case 13: // enter
      case 32: // space
        event.preventDefault()
        this.toggle()
    }
  }

  hide (event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false
    }
  }
}
