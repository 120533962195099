export default {
  qlarity: {
    name: 'Qlarity',
    uri: 'http://www.qlarity.com/schema/bpmn/qlarity',
    prefix: 'qlarity',
    xml: { tagAlias: 'lowerCase' },
    types: [
      {
        name: 'QlarityTaskDetails',
        extends: [
          'bpmn:Task'
        ],
        properties: [
          {
            name: 'activityId',
            isAttr: true,
            type: 'Integer'
          },
          {
            name: 'businessProcedureId',
            isAttr: true,
            type: 'Integer'
          },
          {
            name: 'businessProcedureLabel',
            isAttr: true,
            type: 'String'
          }
        ]
      }
    ]
  }
}
