export class CustomContextPad {
  constructor (contextPad) {
    contextPad.registerProvider(1000, this)
  }

  getContextPadEntries (element) {
    return function (entries) {
      // delete entries["append.gateway"]
      // delete entries["append.end-event"]
      // delete entries["replace"]
      // delete entries["delete"]
      // delete entries["connect"]
      delete entries['append.text-annotation']
      delete entries['append.intermediate-event']

      return entries
    }
  }
}

CustomContextPad.$inject = [
  'contextPad'
]
