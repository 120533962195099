import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="action-text"
export default class extends Controller {
  connect () {
    window.addEventListener('turbo:morph', this.reset)
  }

  reset = (_event) => {
    this.trixEditorElement.parentNode.replaceChild(
      this.trixEditorElement.cloneNode(true),
      this.trixEditorElement
    )
  }

  toggle () {
    this.trixToolbarElement.classList.toggle('h-8')
  }

  disconnect () {
    window.removeEventListener('turbo:morph', this.reset)
  }

  get trixEditorElement () {
    return this.element.querySelector('trix-editor')
  }

  get trixToolbarElement () {
    return this.element.querySelector('trix-toolbar')
  }
}
