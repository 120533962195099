// The notification panel can be open by clicking a button with data-action="click->application--notifications-panel#toggle".
// This will toggle the hidden class on the overlay and the panel.  The panel is a fixed element that slides in from the right.
// The overlay is a fixed element that covers the entire screen and is used to close the panel when clicked.
//
// Example HTML:
//
// <body data-controller="panel">
// ...
// <button type="button" className="relative -m-2.5 p-2.5 text-gray-400 hover:text-gray-500" data-action="click->panel#toggle" data-panel-src-param="<%= src_url %>">
// ...
// <div class="relative z-10" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
//   <!--Overlay-->
//   <div class="fixed inset-0 hidden" data-application--notifications-panel-target="overlay" data-action="click->panel#toggle"></div>
//   <!--Panel-->
//   <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 hidden" data-panel-target="panel">
//     <div class="pointer-events-auto w-screen max-w-md">
//       <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
//         <%= turbo_frame_tag :notifications, src: notifications_path do %>
//         <% end %>
//       </div>
//     </div>
//   </div>
// </div>
// </body>

import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ['overlay', 'panel', 'frame']
  // declare a variable to track the state of the panel
  hidden = true

  connect () {
    useTransition(this, {
      element: this.panelTarget,
      enterActive: 'transform transition ease-in-out duration-500 sm:duration-700',
      enterFrom: 'translate-x-full',
      enterTo: 'translate-x-0',
      leaveActive: 'transform transition ease-in-out duration-500 sm:duration-700',
      leaveFrom: 'translate-x-0',
      leaveTo: 'translate-x-full'
    })
  }

  // If we are not changing the source, toggle the panel otherwise set the source and show the panel if it's hidden
  toggle (event) {
    if (this.frameTarget.src === event.params.src || event.params.src === undefined) {
      this.toggle_hidden()
    } else {
      this.frameTarget.src = event.params.src
      if (this.hidden) {
        this.toggle_hidden()
      }
    }
  }

  toggle_hidden () {
    this.hidden = !this.hidden
    this.overlayTarget.classList.toggle('hidden')
    this.toggleTransition()
  }
}
