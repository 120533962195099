// How to use the modal:
//
// - add data-action="click->modal#open" to the link that will open the modal.
//
// - In the controller, ensure you render a turbo_stream.update('modal-content', ...), eg
//     render turbo_stream: turbo_stream.update('modal-content', partial: 'my_partial')

import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['modal', 'overlay', 'panel']
  static values = { open: Boolean }

  connect () {
    useClickOutside(this, { element: this.panelTarget })
  }

  open (event) {
    this.modalTarget.classList.remove('hidden')
    setTimeout(
      () => {
        this.panelTarget.classList.remove('opacity-0')
        // this.buttonTarget.classList.remove('opacity-0');
        this.overlayTarget.classList.remove('opacity-0')
      }, 10
    )
  }

  close () {
    this.overlayTarget.classList.add('opacity-0')
    this.panelTarget.classList.add('opacity-0')
    // this.buttonTarget.classList.add('opacity-0')
    setTimeout(
      () => {
        this.modalTarget.classList.add('hidden')
      }, 300
    )
  }

  toggle () {
    this.modalTarget.classList.toggle('hidden')
    this.overlayTarget.classList.toggle('hidden')
    this.toggleTransition()
  }

  clickOutside (event) {
    // example to close a modal
    event.preventDefault()
    this.close()
  }
}
