import { Turbo } from '@hotwired/turbo-rails'
import { Editor } from '../bpmn/editor'

import TurboPower from 'turbo_power'

TurboPower.initialize(Turbo.StreamActions)

Turbo.StreamActions.bpmn_redraw_element = function () {
  this.targetElements.forEach(targetElement => {
    const editor = Editor.for(targetElement)
    const elementId = this.getAttribute('element_id')
    const element = editor.getElementById(elementId)

    if (!element) {
      console.info(`No BPMN element with id "${elementId}" available in BPMN viewer with id "${editor.container.id}"`)
      return
    }

    const businessProcedureId = this.getAttribute('business_procedure_id')
    const businessProcedureLabel = this.getAttribute('business_procedure_label')

    element.businessObject.set('businessProcedureId', businessProcedureId)
    element.businessObject.set('businessProcedureLabel', businessProcedureLabel)

    editor.redrawElement(elementId)
    editor.lint()
  })
}

Turbo.config.forms.confirm = (message, element) => {
  const dialog = document.getElementById('turbo-confirm')
  dialog.querySelector('p').textContent = message
  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue === 'confirm')
    }, { once: true })
  })
}
