import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="settings--labels"
export default class extends Controller {
  static targets = ['kind', 'parent']
  static values = { parents: Array }

  kindChanged (event) {
    if (this.parentsValue.includes(this.kindTarget.value)) {
      this.parentTarget.disabled = false
    } else {
      this.parentTarget.disabled = true
      this.parentTarget.value = ''
    }
  }
}
