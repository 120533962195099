import { Controller } from '@hotwired/stimulus'
import { Editor } from '../bpmn/editor'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ['canvas']
  static values = {
    xml: String
  }

  connect () {
    if (!this.bpmn) {
      this.intersection = useIntersection(this)

      this.bpmn = new Editor({
        mode: 'navigated-viewer',
        container: this.canvasTarget,
        lintEnabled: false
      })

      this.bpmn.initialize()
    }
  }

  async appear () {
    await this.bpmn.loadFromXML(this.xmlValue)

    const unobserve = this.intersection[1]
    unobserve()
  }

  disconnect () {
    this.bpmn.destroy()
  }
}
