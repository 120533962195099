import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="inline-edit"
export default class extends Controller {
  static values = {
    content: String
  }

  contentValueChanged (value, previousValue) {
    const classes = ['bg-indigo-100', 'rounded-sm', 'outline', 'outline-4', 'outline-indigo-100', 'animate-pulse-bg-once']

    if (previousValue) {
      this.element.classList.add(...classes)
      setTimeout(() => {
        this.element.classList.remove(...classes)
      }, 2200)
    }
  }
}
