import { Controller } from '@hotwired/stimulus'
/* To use this controller, add the controller an element, and the text for the "show" and "hide" states:
    * data-controller="show-hide"
    * data-show-hide-show-text-value="Show more"
    * data-show-hide-hide-text-value="Show less"

  Add this to the link or button that will toggle the content:
    * data-action="click->show-hide#toggle:prevent"

  Add this to the content that will be shown or hidden:
    * data-show-hide-target="content"

 */
export default class extends Controller {
  static targets = ['content']
  static values = { showText: String, hideText: String }

  toggle (event) {
    if (this.contentTarget.classList.contains('hidden')) {
      event.currentTarget.textContent = this.hideTextValue
      this.contentTarget.classList.remove('hidden')
    } else {
      event.currentTarget.textContent = this.showTextValue
      this.contentTarget.classList.add('hidden')
    }
  }
}
