export default {
  getLintConfig: (baseConfig, overwrites = {}) => {
    return {
      ...baseConfig,
      config: {
        ...baseConfig.config,
        rules: {
          ...baseConfig.config.rules,
          ...overwrites
        }
      }
    }
  }
}
