import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer'

import { is } from 'bpmn-js/lib/util/ModelUtil'
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil'

import {
  append as svgAppend,
  attr as svgAttr,
  create as svgCreate
} from 'tiny-svg'

const HIGH_PRIORITY = 1500

export class CustomRenderer extends BaseRenderer {
  constructor (eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY)

    this.bpmnRenderer = bpmnRenderer
  }

  canRender (element) {
    return isAny(element, ['bpmn:Task']) && !element.labelTarget
  }

  async drawShape (parentNode, element) {
    const shape = this.bpmnRenderer.drawShape(parentNode, element)

    if (is(element, 'bpmn:Task') && element.parent) {
      if (element.businessObject.activityId &&
        element.businessObject.businessProcedureId) {
        setTimeout(async () => {
          const id = `text_for_${element.id}`
          const text = svgCreate('text')
          text.innerHTML = element.businessObject.businessProcedureLabel

          svgAttr(text, { id, 'font-size': '10pt' })

          svgAppend(parentNode, text)

          const { width: textWidth } = text.getBoundingClientRect()
          const {
            width: shapeWidth,
            height: shapeHeight
          } = shape.getBoundingClientRect()

          svgAttr(text, {
            x: (shapeWidth - textWidth) / 2,
            y: shapeHeight + 24,
            fill: element.businessObject.businessProcedureId ? 'gray' : 'red'
          })
        }, 1)
      }
      return shape
    }

    return shape
  }
}

CustomRenderer.$inject = [
  'eventBus',
  'bpmnRenderer'
]
