import { is } from 'bpmn-js/lib/util/ModelUtil'
import * as helpers from './helpers'

export class CustomPopupMenu {
  constructor (popupMenu, elementRegistry) {
    popupMenu.registerProvider('bpmn-replace', this)

    this.elementRegistry = elementRegistry
  }

  getPopupMenuHeaderEntries (target) {
    return (_entries) => {
      // delete entries["toggle-loop"]
      // delete entries["toggle-parallel-mi"]
      // delete entries["toggle-sequential-mi"]

      return []
    }
  }

  getPopupMenuEntries (element) {
    return (entries) => {
      if (helpers.isAnyTask(element)) {
        // delete entries["replace-with-task"]
        // delete entries["replace-with-user-task"]
        // delete entries["replace-with-service-task"]
        delete entries['replace-with-send-task']
        delete entries['replace-with-receive-task']
        // delete entries["replace-with-manual-task"]
        delete entries['replace-with-rule-task']
        delete entries['replace-with-script-task']
        // delete entries["replace-with-call-activity"]
        delete entries['replace-with-collapsed-subprocess']
        delete entries['replace-with-expanded-subprocess']
      }

      if (helpers.isAnyStartEvent(element)) {
        // delete entries["replace-with-none-start"]
        delete entries['replace-with-none-intermediate-throwing']
        // delete entries["replace-with-none-end"]
        // delete entries["replace-with-message-start"]
        // delete entries["replace-with-timer-start"]
        delete entries['replace-with-conditional-start']
        delete entries['replace-with-signal-start']
      }

      if (helpers.isAnyEndEvent(element)) {
        // delete entries["replace-with-none-end"]
        // delete entries["replace-with-none-start"]
        delete entries['replace-with-none-intermediate-throw']
        // delete entries["replace-with-message-end"]
        delete entries['replace-with-escalation-end']
        delete entries['replace-with-error-end']
        delete entries['replace-with-compensation-end']
        delete entries['replace-with-signal-end']
        // delete entries["replace-with-terminate-end"]
      }

      if (helpers.isAnyIntermediateThrowEvent(element)) {
        delete entries['replace-with-none-start']
        delete entries['replace-with-none-end']
        delete entries['replace-with-message-intermediate-catch']
        delete entries['replace-with-message-intermediate-throw']
        delete entries['replace-with-timer-intermediate-catch']
        delete entries['replace-with-escalation-intermediate-throw']
        delete entries['replace-with-conditional-intermediate-catch']
        delete entries['replace-with-link-intermediate-catch']
        delete entries['replace-with-link-intermediate-throw']
        delete entries['replace-with-compensation-intermediate-throw']
        delete entries['replace-with-signal-intermediate-catch']
        delete entries['replace-with-signal-intermediate-throw']
      }

      if (helpers.isAnyIntermediateCatchEvent(element)) {
        return []
      }

      if (helpers.isAnyGateway(element)) {
        // delete entries["replace-with-exclusive-gateway"]
        // delete entries["replace-with-parallel-gateway"]
        delete entries['replace-with-inclusive-gateway']
        delete entries['replace-with-complex-gateway']
        delete entries['replace-with-event-based-gateway']
      }

      if (helpers.isSubProcess(element)) {
        delete entries['replace-with-transaction']
        delete entries['replace-with-subprocess']
        delete entries['replace-with-event-subprocess']
        delete entries['replace-with-collapsed-subprocess']
      }

      if (helpers.isAnyFlow(element)) {
        // delete entries["replace-with-sequence-flow"]
        // delete entries["replace-with-message-flow"]
        // delete entries["replace-with-data-flow"]
        delete entries['replace-with-default-flow']
        delete entries['replace-with-conditional-flow']
      }

      if (helpers.isAnyAssociation(element)) {
        delete entries['replace-with-data-association']
        delete entries['replace-with-data-input-association']
        delete entries['replace-with-data-output-association']
      }

      if (is(element, 'bpmn:Participant')) {
        // delete entries["replace-with-collapsed-pool"]

        if (helpers.hasExpandedPool(this.elementRegistry)) {
          delete entries['replace-with-expanded-pool']
        }
      }

      if (is(element, 'bpmn:Group')) {
        return []
      }

      if (is(element, 'bpmn:Process')) {
        return []
      }

      if (is(element, 'bpmn:Collaboration')) {
        return []
      }

      if (is(element, 'bpmn:Lane')) {
        return []
      }

      return entries
    }
  }
}

CustomPopupMenu.$inject = [
  'popupMenu',
  'elementRegistry'
]
