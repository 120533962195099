import Chartkick from 'chartkick'

Chartkick.config.autoDestroy = false

document.addEventListener('turbo:morph', () => {
  Chartkick.eachChart((chart) => {
    const values = JSON.parse(chart.element.closest('[data-values]')?.dataset?.values || '[["No data", 0]]')
    chart.updateData(values)
  })
})
