import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'
import Sortable from 'sortablejs'

// Connects to data-controller="application--sortable"
export default class extends Controller {
  static targets = ['counter', 'handle']
  static values = {
    draggable: { type: String, default: '.item' }, // ".item" is the default value
    group: { type: String, default: 'name' }, // "name" is the default value
    disabled: { type: Boolean, default: true } // disabled by default
  }

  get reorderButtonElements () {
    return document.querySelectorAll('.reorder-button')
  }

  connect () {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      disabled: this.disabledValue,
      draggable: this.draggableValue,
      group: this.groupValue,
      onEnd: this.end.bind(this)
    })
    this.reorderButtonElements.forEach((button) => {
      button.addEventListener('click', this.toggleState.bind(this))
    })
  }

  end (event) {
    patch(event.item.dataset.url, {
      body: {
        test_script_step: {
          position: event.newIndex + 1
        }
      }
    })
  }

  toggleState () {
    this.disabledValue = !this.disabledValue
    this.sortable.option('disabled', this.disabledValue)
    // Loop over each counter target and update the value to its index + 1
    if (this.hasCounterTarget) {
      this.counterTargets.forEach((counter, index) => {
        counter.textContent = index + 1
      })
    }
    // Toggle between showing the value and the handle
    if (this.hasHandleTarget) {
      this.handleTargets.forEach(handle => handle.classList.toggle('hidden'))
    }
    // If we have any reorderButtonElements, toggle them
    if (this.reorderButtonElements) {
      this.reorderButtonElements.forEach((button) => button.classList.toggle('hidden'))
    }
  }
}
