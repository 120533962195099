import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

// Connects to data-controller="business-repository--code"
export default class extends Controller {
  static targets = ['input', 'prefix']

  async updatePrefix (event) {
    // If the value is nil, use '%00' (URL encoded NULL) as the default
    const response = await get(`/settings/labels/${event.target.value || '0'}`)
    if (response.ok) {
      const json = await response.json
      this.prefixTarget.innerText = json.code_prefix
      this.inputTarget.placeholder = json.code_placeholder
    }
  }
}
