import { Controller } from '@hotwired/stimulus'
import { Editor } from '../bpmn/editor'

import { useApplication, useWindowResize, useDebounce } from 'stimulus-use'

export default class extends Controller {
  static debounces = ['windowResize']
  static targets = ['canvas']
  static values = {
    url: String,
    lintRules: Object,
    lintEnabled: {
      type: Boolean,
      default: true
    }
  }

  connect () {
    useApplication(this)
    useWindowResize(this)
    useDebounce(this)
  }

  windowResize () {
    this.bpmn.autoFit()
    this.bpmn.redrawActivties()
  }

  async initializeCanvas () {
    if (this.isPreview) return
    if (!this.hasCanvasTarget) return

    if (!this.bpmn) {
      this.bpmn = new Editor({
        mode: 'viewer',
        container: this.canvasTarget,
        lintEnabled: this.lintEnabledValue,
        lintingRules: this.lintRules
      })

      this.bpmn.initialize()
    }

    if (this.activityFrameElement) {
      this.bpmn.instance.on('element.click', ({ element }) => {
        if (element.type.endsWith('Task')) {
          this.activityFrameElement.src = this.activityFramePath(element)
        } else {
          this.closePanel()
        }
      })
    }

    return await this.loadDiagram()
  }

  disconnect () {
    if (this.bpmn) {
      this.bpmn.destroy()
    }
  }

  async loadDiagram () {
    return await this.bpmn.loadFromURL(this.urlValue)
  }

  closePanel () {
    this.activityFrameElement.removeAttribute('src')
    this.activityFrameElement.innerHTML = ''
  }

  canvasTargetConnected () {
    this.initializeCanvas()
  }

  urlValueChanged (value, previousValue) {
    if (!previousValue) return
    if (!this.bpmn) return

    this.loadDiagram()
  }

  activityFramePath (element) {
    const { activityId, businessProcedureId } = element.businessObject

    if (activityId && businessProcedureId) {
      return `/activities/${activityId}/business_procedures/${businessProcedureId}`
    } else if (activityId) {
      return `/activities/${activityId}/business_procedures`
    } else {
      console.warn('No activityId for element when trying to get activityFramePath', element)
    }

    return null
  }

  get activityFrameElement () {
    return document.querySelector('turbo-frame#activity')
  }
}
