import { is } from 'bpmn-js/lib/util/ModelUtil'
import { isEventSubProcess } from 'bpmn-js/lib/util/DiUtil'

export function diagramElements (elementRegistry) {
  return elementRegistry.getAll()
}

export function participants (elementRegistry) {
  return diagramElements(elementRegistry).filter(element => element.type === 'bpmn:Participant')
}

export function diagramElementsCount (elementRegistry) {
  return tally(diagramElements(elementRegistry).map(element => element.type))
}

export function hasPool (elementRegistry) {
  const pools = diagramElementsCount(elementRegistry)['bpmn:Collaboration']

  return Number(pools) > 0
}

export function hasExpandedPool (elementRegistry) {
  const processRefs = participants(elementRegistry).map(participant => participant.businessObject.processRef).filter(ref => ref)

  return processRefs.length > 0
}

export function tally (array) {
  return array.reduce((acc, curr) => {
    acc[curr] ? ++acc[curr] : acc[curr] = 1

    return acc
  }, {})
}

export function isSubProcess (element) {
  return is(element, 'bpmn:SubProcess') &&
    !is(element, 'bpmn:Transaction') &&
    !isEventSubProcess(element)
}

export function isAnyTask (element) {
  return (
    is(element, 'bpmn:Task') ||
    is(element, 'bpmn:SendTask') ||
    is(element, 'bpmn:ReceiveTask') ||
    is(element, 'bpmn:UserTask') ||
    is(element, 'bpmn:ScriptTask') ||
    is(element, 'bpmn:CallActivity') ||
    is(element, 'bpmn:RuleTask')
  )
}

export function isAnyStartEvent (element) {
  return (
    is(element, 'bpmn:StartEvent')
  )
}

export function isAnyEndEvent (element) {
  return (
    is(element, 'bpmn:EndEvent')
  )
}

export function isAnyIntermediateThrowEvent (element) {
  return (
    is(element, 'bpmn:IntermediateThrowEvent')
  )
}

export function isAnyIntermediateCatchEvent (element) {
  return (
    is(element, 'bpmn:IntermediateCatchEvent')
  )
}

export function isAnyGateway (element) {
  return (
    is(element, 'bpmn:ExclusiveGateway') ||
    is(element, 'bpmn:ParallelGateway') ||
    is(element, 'bpmn:InclusiveGateway') ||
    is(element, 'bpmn:ComplexGateway') ||
    is(element, 'bpmn:EventBasedGateway')
  )
}

export function isAnyFlow (element) {
  return (
    is(element, 'bpmn:SequenceFlow') ||
    is(element, 'bpmn:ConditionalFlow') ||
    is(element, 'bpmn:MessageFlow')
  )
}

export function isAnyAssociation (element) {
  return (
    is(element, 'bpmn:DataInputAssociation') ||
    is(element, 'bpmn:DataOutputAssociation')
  )
}
