import { Turbo } from '@hotwired/turbo-rails'
import { Controller } from '@hotwired/stimulus'

import { Editor } from '../bpmn/editor'

import { patch } from '@rails/request.js'
import { useApplication } from 'stimulus-use'
import { beforePageUnload } from '../utils'

export default class extends Controller {
  static targets = [
    'canvas',
    'dropzone',
    'file',
    'controls',
    'error'
  ]

  static values = {
    url: String,
    lintRules: Object,
    lintEnabled: {
      type: Boolean,
      default: true
    }
  }

  connect () {
    useApplication(this)
  }

  disconnect () {
    if (this.bpmn) {
      this.bpmn.destroy()
      this.allowLeaving()
    }
  }

  beforeUnloadHandler = (event) => beforePageUnload(event)

  initializeCanvas () {
    if (this.isPreview) return
    if (!this.hasCanvasTarget) return

    if (!this.bpmn) {
      this.bpmn = new Editor({
        mode: 'modeler',
        container: this.canvasTarget,
        lintEnabled: this.lintEnabledValue,
        lintRules: this.lintRulesValue
      })

      this.bpmn.initialize()
    }

    this.bpmn.instance.on('import.done', (event) => {
      this.hideDropzone()

      if (event.warnings.length > 0) {
        for (const warning of event.warnings) {
          console.warn(warning)
        }
      }

      this.bpmn.redrawPalette()
    })

    this.bpmn.instance.on('element.changed', 1000, ({ element }) => {
      this.preventLeaving()

      if (element.type === 'bpmn:Participant') {
        this.bpmn.redrawPalette()
      }
    })

    if (this.hasFileTarget) {
      this.fileTarget.addEventListener('change', (event) => {
        const input = event.target
        const file = input.files[0]

        if (file) {
          this.bpmn.loadFromFile(file)
          input.value = ''
          this.showCanvas()
        }
      })
    }

    this.loadDiagram()
  }

  canvasTargetConnected () {
    this.initializeCanvas()
  }

  async loadEmptyDiagram () {
    await this.bpmn.loadEmptyDiagram()

    this.showCanvas()
  }

  showControls () {
    this.controlsTarget.classList.remove('group-[.inline-edit]:hidden')
  }

  hideControls () {
    this.controlsTarget.classList.add('group-[.inline-edit]:hidden')
  }

  showDropzone () {
    this.dropzoneTarget.classList.remove('hidden')
    this.canvasTarget.classList.add('hidden')
  }

  hideDropzone () {
    this.dropzoneTarget.classList.add('hidden')
  }

  showCanvas () {
    this.hideDropzone()
    this.showControls()
    this.canvasTarget.classList.remove('hidden')
  }

  hideCanvas () {
    this.canvasTarget.classList.add('hidden')
  }

  showError () {
    this.hideControls()
    this.canvasTarget.classList.add('blur-sm')
    this.errorTarget.classList.remove('hidden')
  }

  hideError () {
    this.canvasTarget.classList.remove('blur-sm')
    this.errorTarget.classList.add('hidden')
  }

  async loadDiagram () {
    this.showCanvas()

    const loaded = await this.bpmn.loadFromURL(this.urlValue)

    if (!loaded) {
      await this.showDropzone()
      this.hideControls()
    }
  }

  preventLeaving () {
    document.addEventListener('turbo:before-visit', this.beforeUnloadHandler)
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
  }

  allowLeaving () {
    document.removeEventListener('turbo:before-visit', this.beforeUnloadHandler)
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  }

  async save () {
    this.allowLeaving()

    try {
      const bpmn = await this.bpmn.diagramXML()
      const body = { business_process: { bpmn } }
      const response = await patch(window.location.href,
        { body, responseKind: 'json' })

      if (response.ok) {
        Turbo.visit(response.response.url, { frame: this.turboFrameId })
      } else {
        this.showError()
        console.error('Response not successful', response)
      }
    } catch (err) {
      this.showError()
      console.error(err)
    }
  }

  async download (event) {
    const fallback = this.turboFrameId.replace('turbo_frame', '')

    this.#downloadFile(`${event.target.dataset.filename || fallback}.bpmn`, await this.bpmn.diagramXML())
  }

  #downloadFile (filename, text) {
    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', filename)
    element.style.display = 'none'

    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  get turboFrameId () {
    return this.element.closest('turbo-frame')?.id
  }
}
