import { Controller } from '@hotwired/stimulus'
import { useWindowResize } from 'stimulus-use'

export default class extends Controller {
  static targets = ['content', 'more', 'less']
  static values = { expanded: Boolean }

  connect () {
    useWindowResize(this)
    this.reflect()
  }

  toggle () {
    this.expandedValue = !this.expandedValue
  }

  expandedValueChanged (value, previousValue) {
    if (!this.hasExpandedAttribute) {
      this.expandedValue = previousValue
      return
    }

    if (this.expandedValue) {
      this.contentTarget.classList.remove('line-clamp-3')
      this.moreTarget.classList.add('hidden')
      this.lessTarget.classList.remove('hidden')
    } else {
      this.contentTarget.classList.add('line-clamp-3')
      this.moreTarget.classList.remove('hidden')
      this.lessTarget.classList.add('hidden')
      this.reflect()
    }
  }

  windowResize () {
    this.expandedValue = false
    this.reflect()
  }

  reflect () {
    if (this.isClamped) {
      this.moreTarget.classList.remove('hidden')
    } else {
      this.moreTarget.classList.add('hidden')
      this.lessTarget.classList.add('hidden')
    }
  }

  get isClamped () {
    if (!this.hasContentTarget) return false

    return this.contentTarget.scrollHeight > this.contentTarget.clientHeight
  }

  get hasExpandedAttribute () {
    return this.element.hasAttribute(
      this.data.getAttributeNameForKey('expanded-value')
    )
  }
}
