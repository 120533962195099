import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="test-script--failure-reason"

// This controller is used to enable the failure reason input field when the minor defect check box is selected.
// Test result = Failed -> Minor Defect is disabled & Failure reason is enabled
// Test result = Passed -> Minor Defect is enabled & Failure reason is disabled
// Test result = Passes with minor defects -> Failure reason is enabled
export default class extends Controller {
  static targets = ['code', 'failed', 'minor', 'passed']

  // If the test result is "Failed", enable the failure reason input field
  failed () {
    // disable and uncheck the minor defect checkbox
    this.disableMinor()
    // enable the failure reason input field
    this.enableCode()
  }

  minor () {
    if (this.minorTarget.checked) {
      this.enableCode()
    } else {
      this.disableCode()
    }
  }

  passed () {
    if (this.codeTarget.value) {
      this.minorTarget.checked = true
      this.enableCode()
    } else {
      this.disableCode()
    }
    this.enableMinor()
  }

  disableCode () {
    if (this.codeTarget.tomselect) {
      this.codeTarget.tomselect.clear()
      this.codeTarget.tomselect.disable()
    } else {
      this.codeTarget.value = ''
      this.codeTarget.disabled = true
    }
  }

  enableCode () {
    if (this.codeTarget.tomselect) {
      this.codeTarget.tomselect.enable()
    } else {
      this.codeTarget.disabled = false
    }
  }

  disableMinor () {
    this.minorTarget.disabled = true
    this.minorTarget.checked = false
  }

  enableMinor () {
    this.minorTarget.disabled = false
  }
}
