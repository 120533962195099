import { Controller } from '@hotwired/stimulus'
import { useApplication } from 'stimulus-use'
import consumer from '../channels/consumer'

// Connects to data-controller="occupancy"
export default class extends Controller {
  static targets = ['input']
  static values = {
    attribute: String,
    gid: String
  }

  connect () {
    useApplication(this)
  }

  occupy (event) {
    this.element.closest('form')?.setAttribute('data-turbo-permanent', 'true')
    this.subscription.perform('occupy', {
      attribute: this.attributeValue
    })
  }

  vacate (event) {
    this.element.closest('form')?.removeAttribute('data-turbo-permanent')
    this.subscription.perform('vacate', {
      attribute: this.attributeValue
    })
  }

  inputTargetConnected (event) {
    if (this.isPreview) return

    this.subscription = consumer.subscriptions.create({
      channel: 'OccupancyChannel',
      gid: this.gidValue
    }, {
      connected: () => this.occupy()
    })
  }

  inputTargetDisconnected (event) {
    if (this.isPreview) return

    this.vacate()
    this.subscription.unsubscribe()
  }
}
