import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="application--mobile-menu"
// This controller works with specially annotated HTML like:
//
// <body data-controller="mobile-menu">
// ...
// <div class="hidden md:hidden" role="dialog" aria-modal="true" data-mobile-menu-target="modal">
//   ...
//   <div class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity ease-linear duration-300 opacity-0" aria-hidden="true" data-mobile-menu-target="overlay"></div>
//     <div class="relative max-w-xs w-full bg-indigo-700 pt-5 pb-4 flex-1 flex flex-col transition ease-in-out duration-300 transform -translate-x-full" data-mobile-menu-target="menu">
//       <div class="absolute top-1 right-0 -mr-14 p-1 ease-in-out duration-300 opacity-0" data-mobile-menu-target="button">
//         <button type="button" class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white" data-action="click->mobile-menu#close">
//         ...
//         </button>
//       </div>
//     </div>
//   </div>
// </body>
export default class extends Controller {
  static targets = ['button', 'menu', 'modal', 'overlay']
  static values = { open: Boolean }

  open (event) {
    this.modalTarget.classList.remove('hidden')
    setTimeout(
      () => {
        this.menuTarget.classList.remove('-translate-x-full')
        this.buttonTarget.classList.remove('opacity-0')
        this.overlayTarget.classList.remove('opacity-0')
      }, 10
    )
  }

  close () {
    this.overlayTarget.classList.add('opacity-0')
    this.menuTarget.classList.add('-translate-x-full')
    this.buttonTarget.classList.add('opacity-0')
    setTimeout(
      () => {
        this.modalTarget.classList.add('hidden')
      }, 300
    )
  }
}
