import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="application--flash"
export default class extends Controller {
  connect () {
    this.show()

    // Hide after 5.0 seconds
    setTimeout(() => {
      this.hide()
    }, 5000)
  }

  show () {
    this.element.classList.remove('translate-y-2', 'opacity-0', 'sm:translate-y-0', 'sm:translate-x-2')

    // After the timeout prepare to Hide
    setTimeout(() => {
      this.element.classList.remove('transform', 'ease-out', 'duration-300')
      this.element.classList.add('ease-in', 'duration-100')
    }, 500)
  }

  hide () {
    this.element.classList.add('opacity-0')

    // Remove the alert after 0.5 seconds
    setTimeout(() => {
      this.element.remove()
    }, 500)
  }
}
