import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="business-repository--search"
export default class extends Controller {
  static targets = ['groupLabelAllButton']

  submit (event) {
    if (event.params.label === 'area' && this.hasGroupLabelAllButtonTarget) {
      this.groupLabelAllButtonTarget.checked = true
    }
    this.element.requestSubmit()
  }
}
