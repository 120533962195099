import { hasExpandedPool } from './helpers'

export class CustomPalette {
  constructor (palette, elementRegistry, elementFactory, create) {
    palette.registerProvider(1000, this)

    this.elementRegistry = elementRegistry
    this.elementFactory = elementFactory
    this.create = create
  }

  getPaletteEntries () {
    return (entries) => {
      // delete entries["hand-tool"]
      // delete entries["lasso-tool"]
      // delete entries["space-tool"]
      // delete entries["global-connect-tool"]
      // delete entries["tool-separator"]
      // delete entries["create.start-event"]
      delete entries['create.intermediate-event']
      // delete entries["create.end-event"]
      // delete entries["create.exclusive-gateway"]
      // delete entries["create.task"]
      // delete entries["create.data-object"]
      // delete entries["create.data-store"]
      delete entries['create.subprocess-expanded']
      delete entries['create.group']

      if (hasExpandedPool(this.elementRegistry)) {
        delete entries['create.participant-expanded']

        entries['expand-subprocess'] = this.createAction(
          'collaboration',
          'Create pool/participant',
          'bpmn-icon-participant',
          'bpmn:Participant'
        )
      }

      return entries
    }
  }

  createAction (group, title, className, type) {
    return {
      group,
      title,
      className,
      action: {
        dragstart: this.startCreateShape(type),
        click: this.startCreateShape(type)
      }
    }
  }

  startCreateShape (type) {
    return this.startCreate(
      this.createShape(type)
    )
  }

  startCreate (shape) {
    return (event) => {
      this.create.start(event, shape)
    }
  }

  createShape (type) {
    return this.elementFactory.create('shape', { type })
  }
}

CustomPalette.$inject = [
  'palette',
  'elementRegistry',
  'elementFactory',
  'create'
]
