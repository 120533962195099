import { CustomRenderer } from './custom_renderer'
import { CustomContextPad } from './custom_context_pad'
import { CustomPopupMenu } from './custom_popup_menu'
import { CustomPalette } from './custom_palette'

export default {
  __init__: [
    'customRenderer',
    'customPopupMenu',
    'customContextPad',
    'customPalette'
  ],
  customRenderer: ['type', CustomRenderer],
  customPopupMenu: ['type', CustomPopupMenu],
  customContextPad: ['type', CustomContextPad],
  customPalette: ['type', CustomPalette]
}
