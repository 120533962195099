import AppSignal from '@appsignal/javascript'
import * as AppSignalStimulus from '@appsignal/stimulus'

import { application } from '../controllers/application'

const appsignal = new AppSignal(window.Current.appsignal)

AppSignalStimulus.installErrorHandler(appsignal, application)

export {
  appsignal
}
